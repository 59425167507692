import React, { useState, useEffect, useContext } from 'react'
import { StyledCheckout } from '../../molecules/CheckoutComponent'
import OrderTotalReceived from '../../molecules/OrderTotalReceived'
import CheckoutHeader from '../../atoms/CheckoutHeader'
import RegularTypo from '../../typography/RegularTypo'
import { UsersContext } from '../../context'
import { useTranslation } from 'react-i18next'
import Spinner from '../../atoms/Spinner'
import { Helmet } from 'react-helmet'
import SmallerTypo from '../../typography/SmallerTypo'
import styled from 'styled-components'
import { formatPriceReceived } from '../../utils/utils'
import MainButtonTypo from '../../molecules/CheckoutComponent/Components/MainButtonTypo'
import { apiLocation } from '../../molecules/CheckoutComponent/const'
import { useQueryParam, StringParam } from 'use-query-params'
import { germany } from '../../initial/vars'

const ProductName = styled(RegularTypo)`
 h5 {
  font-size: 14px;
  line-height: 1.33333333333;
 }
`

const OrderReceived = ({ orderDetails, processing }) => {
  const { setCart, region } = useContext(UsersContext)
  const shippingCost = orderDetails.shippingFee

  const [orderParams] = useQueryParam('order', StringParam)
  const [orderNumber, setOrderNumber] = useState(null)
  const [orderKey, setOrderKey] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(false)
  const [loading, setLoading] = useState(true)
  const [paymentLink, setPaymentLink] = useState(null)


  // Redirect to Mollie after obtaining payment link
  useEffect(() => {
    paymentLink && setTimeout(() => {
      window.location.replace(paymentLink)
    }, 100)
  }, [paymentLink])


  const checkPaymentStatus = async () => {
    await fetch(`${apiLocation}/checkpaymentstatus`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        orderNumber,
        orderKey
      }),
    })
      .then(res => res.json())
      .then(data => setPaymentStatus(data?.status))
      .catch(error => {
        console.error('Error:', error)
      })
  }



  useEffect(() => {
    if (orderDetails.status === "NOT_PAID") {
      germany ? checkPaymentStatus() : setLoading(false)
    } else if (orderDetails.status === "READY_FOR_PROCESSING") {
      setLoading(false)
    }
  }, [orderNumber, orderKey])


  useEffect(() => {
    paymentStatus === 'NOT_PAID' && setLoading(false)
    paymentStatus === 'PAID' && setLoading(false)
  }, [paymentStatus])


  useEffect(() => {
    const [orderNumber, orderKey] = orderParams.split(',')
    setOrderNumber(orderNumber)
    setOrderKey(orderKey)
  }, [orderParams, orderKey])


  useEffect(() => {
    setCart([])
  }, [setCart])

  const { t } = useTranslation()

  const products = orderDetails?.lineItems.map(product =>
    (product = {
      id: product?.offer?.external?.id,
      name: product?.offer?.name,
      quantity: product.quantity,
      price: product?.price?.amount
    })
  )


  const payForOrder = async () => {
    await fetch(`${apiLocation}/payorder`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        orderNumber,
        orderKey
      }),
    })
      .then(res => res.json())
      .then(data => setPaymentLink(data?.paymentLink))
      .catch(error => {
        console.error('Error:', error)
      })

  }



  return (
    <StyledCheckout>
      {processing || loading ? (
        <Spinner full />
      ) : (
        <>
          {paymentStatus === "NOT_PAID" ?
            <CheckoutHeader underlined>
              {t('checkout::not_paid')}
            </CheckoutHeader>
            :
            <CheckoutHeader underlined>
              {t('checkout::thank_you')}
              <br />
              {t('checkout::order_received')}
            </CheckoutHeader>
          }
          {orderDetails && (
            <>
              <p style={{ fontSize: 16 }}>
                <br />
                {t('checkout::order_number') + ': '}
                {orderDetails?.orderNumber}
                <br />
                {t('checkout::date') + ': '}
                {orderDetails?.date.slice(0, 10)}
                <br />
                {t('checkout::payment_method') + ': '}
                {t(`checkout::${orderDetails?.paymentMethod}`)}
                <br />
              </p>

              {orderDetails?.paymentMethod === 'bacs' && (
                <>
                  <p style={{ fontSize: 16 }}>
                    Prosimy o wpłatę bezpośrednio na nasze konto bankowe. Proszę użyć
                    numeru zamówienia jako tytułu płatności. Twoje zamówienie zostanie
                    zrealizowane po zaksięgowaniu wpłaty na naszym koncie.
                  </p>
                  <RegularTypo text="Nasze dane bankowe:" />
                  <RegularTypo text="Konto firmowe Tycho" />
                  <RegularTypo text="Bank: PKO BP S.A." />
                  <RegularTypo text="24 1020 1156 0000 7802 0010 9678" /> <br />
                </>
              )}

              <CheckoutHeader underlined>
                {t('checkout::order_details')}
              </CheckoutHeader>

              <div style={{ marginTop: 0 }}>
                {orderDetails?.lineItems.map((item, index) => {
                  return (
                    <div key={index} style={{ marginTop: 18 }}>
                      <ProductName text={item.offer.name} style={{ lineHeight: 1.4 }} />
                      {item.options?.map((option, index) => (
                        <SmallerTypo
                          key={index}
                          grey
                          text={option}
                          style={{ lineHeight: 1.4 }}
                        />
                      ))}
                      <SmallerTypo
                        text={`${t('checkout::quantity')}: ${item.quantity}`}
                        style={{ lineHeight: 1.4, marginTop: 4 }}
                      />
                    </div>
                  )
                })}
              </div>

              <OrderTotalReceived
                subtotal={parseFloat(orderDetails?.total.amount - shippingCost)}
                shippingCost={parseFloat(shippingCost)}
                orderTotal={parseFloat(orderDetails?.total.amount)}
                currency={orderDetails?.total.currency}
                vatInfo={formatPriceReceived({ price: parseFloat(orderDetails?.tax), region, currency: orderDetails?.total.currency })}
              />

              {paymentStatus === "NOT_PAID" &&
                <MainButtonTypo type="button" onClick={() => payForOrder()} >
                  <span className="btn_content"> {t('checkout::complete_purchase')} </span>
                </MainButtonTypo>
              }

            </>
          )}
          {/* Trusted */}
          {orderDetails?.orderNumber && (
            <div id="trustedShopsCheckout" style={{ display: 'none' }}>
              <span id="tsCheckoutOrderNr"> {orderDetails?.orderNumber} </span>
              <span id="tsCheckoutBuyerEmail">{orderDetails?.email}</span>
              <span id="tsCheckoutOrderAmount"> {orderDetails?.total.amount} </span>
              <span id="tsCheckoutOrderCurrency">{orderDetails?.total.currency}</span>
              <span id="tsCheckoutOrderPaymentType">
                {orderDetails?.paymentMethod}
              </span>
              <span id="tsCheckoutOrderEstDeliveryDate"></span>
            </div>
          )}

          {/* Google Gtag purchase */}
          <Helmet>
          <script type="text/javascript">
              {`gtag('event', 'purchase', {
          "transaction_id": ${orderDetails?.orderNumber},
          "value": ${orderDetails?.total?.amount},
          "currency": ${JSON.stringify(orderDetails?.total.currency)},
          "tax": ${orderDetails?.tax},
          "shipping": ${orderDetails?.shippingFee},
          "items":  ${JSON.stringify(products)},
          "email": ${orderDetails?.email},
          "phone_number": ${orderDetails?.phone}
        });
      `}
            </script>
            <script type="text/javascript">
              {`dataLayer.push({ ecommerce: null });
            dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id:  ${orderDetails?.orderNumber},
                value: ${orderDetails?.total?.amount},
                tax:  ${orderDetails?.tax},
                shipping: ${orderDetails?.shippingFee},
                currency:  ${JSON.stringify(orderDetails?.total.currency)},
                "items":  ${JSON.stringify(products)},
            }
        });  
      `}
            </script>
          </Helmet>


          
        </>
      )}
    </StyledCheckout>
  )
}

export default OrderReceived
